<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-content>
      <v-list-item-title>
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="formattedItem.respuesta"
      >
        {{ formattedItem.respuesta }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = this.$online.sobreEnvio.title(this.item)
      item.subtitle = this.$online.sobreEnvio.subtitle(this.item)
      item.respuesta = this.item.respuesta
      return item
    }
  },
}
</script>
